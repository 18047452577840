var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pt-7 pb-11"},[(_vm.getLoading)?_c('v-progress-linear',{attrs:{"height":"2","indeterminate":""}}):[_c('v-row',{staticClass:"mt-0 mb-4"},[_c('v-col',[_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadcrumbsItems,"divider":"/"}})],1)],1),_c('v-row',{staticClass:"mb-8",attrs:{"justify":"space-between","align":"center"}},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"auto"}},[_c('h1',{staticClass:"text-h4"},[_vm._v(_vm._s(_vm.pageHeader)+" "),_c('span',{staticClass:"font-weight-regular text-lowercase"},[_vm._v(_vm._s(_vm._f("localize")('relevancy')))])])])],1),_c('div',{staticClass:"white elevation-2 rounded"},[_c('v-container',{staticClass:"px-4"},[_c('v-form',{ref:"userRelevancy",attrs:{"lazy-validation":""}},[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h6',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(_vm._s(_vm._f("localize")('transportMode')))]),_c('v-select',{attrs:{"background-color":_vm.transport_mode ? 'serenade' : '',"items":_vm.transportModeList,"item-text":"value","item-value":"key","dense":"","clearable":"","outlined":"","disabled":_vm.saveLoading,"rules":[_vm.validationRules.required]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"silver-chalice"}},[_vm._v(_vm._s(_vm.TRANSPORT_ICONS[item['value']]))]),_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")(item['value'])))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"silver-chalice"}},[_vm._v(_vm._s(_vm.TRANSPORT_ICONS[item['value']]))]),_c('v-list-item-title',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm._f("localize")(item['value'])))])],1)]}}]),model:{value:(_vm.transport_mode),callback:function ($$v) {_vm.transport_mode=$$v},expression:"transport_mode"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h6',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(_vm._s(_vm._f("localize")('typeOfGoods')))]),_c('v-select',{attrs:{"background-color":_vm.type_of_goods.length ? 'serenade' : '',"items":_vm.goodTypesList,"item-text":"value","item-value":"key","dense":"","clearable":"","outlined":"","multiple":"","disabled":_vm.saveLoading,"rules":[_vm.validationRules.requiredArray]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.value))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[(index > 0)?_c('span',{staticClass:"mr-1"},[_vm._v(", ")]):_vm._e(),_vm._v(" "+_vm._s(item.value)+" ")])]}}]),model:{value:(_vm.type_of_goods),callback:function ($$v) {_vm.type_of_goods=$$v},expression:"type_of_goods"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('h6',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm._f("localize")('fromToPresets'))+" "),_c('span',{staticStyle:{"font-weight":"normal"}},[_vm._v("("+_vm._s(_vm._f("localize")('optional'))+")")])]),_c('v-tooltip',{attrs:{"top":"","color":"black","content-class":"px-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"size":"20","color":"silver-chalice"}},'v-icon',attrs,false),on),[_vm._v("mdi-information ")])]}}])},[[_c('div',{staticClass:"py-1"},[_c('ul',{staticClass:"px-0",staticStyle:{"list-style":"none"}},[_c('li',[_c('strong',[_vm._v(_vm._s(_vm._f("localize")('domestic')))]),_vm._v(" - "+_vm._s(_vm._f("localize")('domesticNote')))]),_c('li',[_c('strong',[_vm._v(_vm._s(_vm._f("localize")('cross')))]),_vm._v(" - "+_vm._s(_vm._f("localize")('crossNote')))]),_c('li',[_c('strong',[_vm._v(_vm._s(_vm._f("localize")('import')))]),_vm._v(" - "+_vm._s(_vm._f("localize")('importNote')))]),_c('li',[_c('strong',[_vm._v(_vm._s(_vm._f("localize")('importEurope')))]),_vm._v(" - "+_vm._s(_vm._f("localize")('importEuropeNote')))]),_c('li',[_c('strong',[_vm._v(_vm._s(_vm._f("localize")('export')))]),_vm._v(" - "+_vm._s(_vm._f("localize")('exportNote')))]),_c('li',[_c('strong',[_vm._v(_vm._s(_vm._f("localize")('exportEurope')))]),_vm._v(" - "+_vm._s(_vm._f("localize")('exportEuropeNote')))]),_c('li',[_c('strong',[_vm._v(_vm._s(_vm._f("localize")('all')))]),_vm._v(" - "+_vm._s(_vm._f("localize")('anyNote')))])])])]],2)],1),_c('v-select',{attrs:{"background-color":_vm.selectedPreset ? 'serenade' : '',"items":_vm.countryPresets,"item-text":"name","item-value":"key","dense":"","clearable":"","outlined":"","disabled":_vm.saveLoading},on:{"change":_vm.presetHandler},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_c('b',[_vm._v(_vm._s(item.name))]),_vm._v(" ("+_vm._s(item.description)+")")])]}}]),model:{value:(_vm.selectedPreset),callback:function ($$v) {_vm.selectedPreset=$$v},expression:"selectedPreset"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h6',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(_vm._s(_vm._f("localize")('deliveryTerms')))]),_c('v-select',{attrs:{"background-color":_vm.delivery_terms.length ? 'serenade' : '',"items":_vm.deliveryTerms,"item-text":"text","item-value":"value","dense":"","clearable":"","outlined":"","multiple":"","placeholder":_vm._f("localize")('selectTerms'),"rules":[_vm.validationRules.requiredArray]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.text))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[(index > 0)?_c('span',{staticClass:"mr-1"},[_vm._v(", ")]):_vm._e(),_vm._v(" "+_vm._s(item.value)+" ")])]}}]),model:{value:(_vm.delivery_terms),callback:function ($$v) {_vm.delivery_terms=$$v},expression:"delivery_terms"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h6',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(_vm._s(_vm._f("localize")('regionFrom'))+" "),_c('span',{staticStyle:{"font-weight":"normal"}},[_vm._v("("+_vm._s(_vm._f("localize")('optional'))+")")])]),_c('v-select',{attrs:{"background-color":_vm.region_from.length ? 'serenade' : '',"items":_vm.REGIONS,"item-text":function (item) { return ("" + (item.value)); },"item-value":function (item) { return item.key; },"dense":"","clearable":"","outlined":"","multiple":"","disabled":_vm.saveLoading},on:{"change":function($event){return _vm.regionHandler('region_from')}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.value))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[(index > 0)?_c('span',{staticClass:"mr-1"},[_vm._v(", ")]):_vm._e(),_vm._v(" "+_vm._s(item.value)+" ")])]}}]),model:{value:(_vm.region_from),callback:function ($$v) {_vm.region_from=$$v},expression:"region_from"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h6',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(_vm._s(_vm._f("localize")('countryFrom')))]),_c('v-autocomplete',{attrs:{"background-color":_vm.country_from.length ? 'serenade' : '',"items":_vm.COUNTRY_LIST,"item-text":function (item) { return ((item.name) + " " + (item.code)); },"item-value":function (item) { return ("" + (item.code)); },"dense":"","clearable":"","outlined":"","multiple":"","disabled":_vm.saveLoading,"rules":[_vm.validationRules.requiredArray]},on:{"change":_vm.clearFromPresets},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.code)+")")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index <= 3)?_c('span',[(index > 0)?_c('span',[_vm._v(", ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(index === _vm.country_from.length - 1)?_c('span',{staticClass:"mr-1"}):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption mx-1"},[_vm._v("+"+_vm._s(_vm.country_from.length - 4))]):_vm._e()]}}]),model:{value:(_vm.country_from),callback:function ($$v) {_vm.country_from=$$v},expression:"country_from"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h6',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(_vm._s(_vm._f("localize")('regionTo'))+" "),_c('span',{staticStyle:{"font-weight":"normal"}},[_vm._v("("+_vm._s(_vm._f("localize")('optional'))+")")])]),_c('v-select',{attrs:{"background-color":_vm.region_to.length ? 'serenade' : '',"items":_vm.REGIONS,"item-text":function (item) { return ("" + (item.value)); },"item-value":function (item) { return item.key; },"dense":"","clearable":"","outlined":"","multiple":"","disabled":_vm.saveLoading},on:{"change":function($event){return _vm.regionHandler('region_to')}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.value))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[(index > 0)?_c('span',{staticClass:"mr-1"},[_vm._v(", ")]):_vm._e(),_vm._v(" "+_vm._s(item.value)+" ")])]}}]),model:{value:(_vm.region_to),callback:function ($$v) {_vm.region_to=$$v},expression:"region_to"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{staticClass:"switch-button-holder"},[_c('v-btn',{staticClass:"switch-button",attrs:{"icon":"","color":"primary"},on:{"click":_vm.switchCountryValues}},[_c('v-icon',[_vm._v("mdi-swap-vertical")])],1)],1),_c('h6',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(_vm._s(_vm._f("localize")('countryTo')))]),_c('v-autocomplete',{attrs:{"background-color":_vm.country_to.length ? 'serenade' : '',"items":_vm.COUNTRY_LIST,"item-text":function (item) { return ((item.name) + " " + (item.code)); },"item-value":function (item) { return ("" + (item.code)); },"dense":"","clearable":"","outlined":"","multiple":"","disabled":_vm.saveLoading,"rules":[_vm.validationRules.requiredArray]},on:{"change":_vm.clearToPresets},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.name)+" ("+_vm._s(item.code)+")")])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index <= 3)?_c('span',[(index > 0)?_c('span',[_vm._v(", ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(index === _vm.country_to.length - 1)?_c('span',{staticClass:"mr-1"}):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption mx-1"},[_vm._v("+"+_vm._s(_vm.country_to.length - 4))]):_vm._e()]}}]),model:{value:(_vm.country_to),callback:function ($$v) {_vm.country_to=$$v},expression:"country_to"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('h6',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(_vm._s(_vm._f("localize")('specialRequirements')))]),_c('v-select',{attrs:{"background-color":_vm.special_requirements.length ? 'serenade' : '',"items":_vm.requirementsList,"item-text":function (item) { return ("" + (item.value)); },"item-value":function (item) { return ("" + (item.key)); },"dense":"","clearable":"","outlined":"","multiple":"","disabled":_vm.saveLoading,"rules":[_vm.validationRules.requiredArray]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":_vm.SPECIAL_REQUIREMENTS_COLORS[item['key']]}},[_vm._v(_vm._s(_vm.SPECIAL_REQUIREMENTS_ICONS[item['key']]))]),_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")(item['key'])))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":_vm.SPECIAL_REQUIREMENTS_COLORS[item['key']]}},[_vm._v(_vm._s(_vm.SPECIAL_REQUIREMENTS_ICONS[item['key']]))]),_c('v-list-item-title',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm._f("localize")(item['key'])))])],1)]}}]),model:{value:(_vm.special_requirements),callback:function ($$v) {_vm.special_requirements=$$v},expression:"special_requirements"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('h6',{staticClass:"text-subtitle-1 mb-2"},[_vm._v(_vm._s(_vm._f("localize")('receiver')))]),_c('v-select',{attrs:{"background-color":_vm.receiver.length ? 'serenade' : '',"items":_vm.receiverList,"item-text":"value","item-value":"key","dense":"","clearable":"","outlined":"","multiple":"","disabled":_vm.saveLoading,"rules":[_vm.validationRules.requiredArray]},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_vm._v(_vm._s(item.value))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('span',[(index > 0)?_c('span',[_vm._v(", ")]):_vm._e(),_vm._v(" "+_vm._s(item.value)+" ")])]}}]),model:{value:(_vm.receiver),callback:function ($$v) {_vm.receiver=$$v},expression:"receiver"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',{staticClass:"ml-auto",attrs:{"cols":"6"}},[_c('div',{staticClass:"d-flex align-center mb-2"},[_c('h6',{staticClass:"text-subtitle-1"},[_vm._v(_vm._s(_vm._f("localize")('allowedClients')))]),_c('v-btn',{staticClass:"ml-auto",attrs:{"text":"","small":"","color":"primary"},on:{"click":_vm.selectAllAllowedClients}},[_vm._v(_vm._s(_vm._f("localize")('selectAll')))])],1),_c('v-autocomplete',{attrs:{"background-color":_vm.allowed_clients.length ? 'serenade' : '',"items":_vm.clients,"item-text":function (item) { return ((item.name) + " " + (item.id) + " " + (item.email)); },"item-value":"user_id","dense":"","clearable":"","outlined":"","multiple":"","loading":_vm.clientsLoading,"disabled":_vm.saveLoading || _vm.clientsLoading},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_c('strong',[_vm._v(_vm._s(item.name))]),_vm._v(" "),_c('i',[_vm._v("(ID:"+_vm._s(item.id)+", "+_vm._s(item.email)+")")])])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index <= 3)?_c('span',[(index > 0)?_c('span',[_vm._v(", ")]):_vm._e(),_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(index === _vm.allowed_clients.length - 1)?_c('span',{staticClass:"mr-1"}):_vm._e(),(index === 4)?_c('span',{staticClass:"grey--text text-caption mx-1"},[_vm._v("+"+_vm._s(_vm.allowed_clients.length - 4))]):_vm._e()]}}]),model:{value:(_vm.allowed_clients),callback:function ($$v) {_vm.allowed_clients=$$v},expression:"allowed_clients"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.relevancy_id)?_c('v-btn',{attrs:{"rounded":"","depressed":"","color":"error","loading":_vm.deleteLoading,"disabled":_vm.saveLoading || _vm.deleteLoading},on:{"click":function($event){return _vm.$refs.deleteRelevancyDialog.open()}}},[_vm._v(" "+_vm._s(_vm._f("localize")('delete'))+" ")]):_vm._e()],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"rounded":"","depressed":"","color":"primary","loading":_vm.saveLoading,"disabled":_vm.saveLoading || _vm.deleteLoading},on:{"click":_vm.openConfirmDialog}},[_vm._v(" "+_vm._s(_vm._f("localize")('save'))+" ")])],1)],1)],1)],1)],1)],_c('SimpleDialog',{ref:"relevancyDialog",attrs:{"title":_vm._f("localize")('saveUserRelevancy'),"text":_vm._f("localize")('saveUserRelevancyText'),"accept-text":_vm._f("localize")('save'),"decline-text":_vm._f("localize")('back')},on:{"decline":function($event){return _vm.$refs.relevancyDialog.close()},"accept":_vm.saveRelevancy}}),_c('SimpleDialog',{ref:"deleteRelevancyDialog",attrs:{"title":_vm._f("localize")('deleteRelevancy'),"text":_vm._f("localize")('deleteRelevancyText'),"accept-text":_vm._f("localize")('delete'),"decline-text":_vm._f("localize")('back')},on:{"decline":function($event){return _vm.$refs.deleteRelevancyDialog.close()},"accept":_vm.deleteRelevancy}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }